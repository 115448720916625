// import $ from 'jquery'
import { html } from 'common-tags'
import { isEqual } from 'date-fns'
import { format, toDate } from 'date-fns-tz'
import Request from 'superagent'
import 'babel-polyfill'
// import 'date-time-format-timezone'
// eslint-disable-next-line
;(function ($) {
	const event_container = $('div.citizen-connect-events')
	const event_api_params = {
		cache: true,
		featured: false,
		per_page: 10,
		paginate: false,
		organization: 0,
		msg_count: 150,
	}
	const baseScriptUrl = () => {
		var script = document.currentScript || document.querySelector('script[src*="bundle.events-widget-v1.js"]')
		var url = new URL(script.src)
		return url.protocol + '//' + url.hostname
	}
	const api_path = '/wp-json/tribe/events/v1/events/'
	const isBoolean = val => 'boolean' === typeof val
	let eventMonth = ''
	let api_params = {}

	function loadData() {
		if (event_container.length) {
			var baseUrl = baseScriptUrl()
			var filterParams = new URLSearchParams()
			api_params.cache = isBoolean($(event_container).data('enableCache'))
				? $(event_container).data('enableCache')
				: event_api_params.cache
			api_params.featured = isBoolean($(event_container).data('featured'))
				? $(event_container).data('featured')
				: event_api_params.featured
			api_params.per_page =
				(Number.isInteger($(event_container).data('perPage')) && $(event_container).data('perPage')) ||
				event_api_params.per_page
			api_params.paginate =
				(Number.isInteger($(event_container).data('paginate')) && $(event_container).data('paginate')) ||
				event_api_params.paginate
			api_params.organization =
				(Number.isInteger($(event_container).data('organization')) &&
					$(event_container).data('organization')) ||
				event_api_params.organization
			api_params.msg_count = $(event_container).data('descriptionLength') || event_api_params.msg_count

			//NWOC
			api_params.nwoc = isBoolean($(event_container).data('nwoc'))
				? $(event_container).data('nwoc')
				: event_api_params.nwoc
			if (api_params.nwoc) filterParams.append('event_nwoc', 1)
			api_params.nwoc_sector = $(event_container).data('nwoc_sector')
				? $(event_container).data('nwoc_sector')
				: event_api_params.nwoc_sector
			if (api_params.nwoc_sector) filterParams.append('nwoc_sector', api_params.nwoc_sector)
			//END NWOC

			filterParams.append('start_date', format(new Date(), 'yyyy-MM-dd'))
			if (api_params.featured) filterParams.append('event_featured', 1)
			if (0 < api_params.per_page) filterParams.append('per_page', api_params.per_page)
			if (0 < api_params.organization) filterParams.append('organization', api_params.organization)
			if (!api_params.cache) filterParams.append('t', Date.now().valueOf())
			filterParams.append('content_count', api_params.msg_count)

			//console.log('filter params ', filterParams.toString())

			Request.get(
				baseUrl + api_path + ('' === filterParams.toString() ? '' : '?' + filterParams.toString())
			).then(response => {
				if (
					Object.prototype.hasOwnProperty.call(response.body, 'events') &&
					Object.keys(response.body.events).length
				)
					displayEventListView(response.body.events)
			})
		}
	}

	function displayEventListView(events) {
		let eventList = events
			.map(function (event) {
				return eventListRecord({ event })
			})
			.join('')
		$(event_container).empty().append(eventList)
	}

	var eventListRecord = ({ event }) => {
		var eventTime = []
		var start_date = toDate(event.start_date + ' ' + event.timezone)
		var end_date = toDate(event.end_date + ' ' + event.timezone)
		var monthHeading = ''

		eventTime.push(format(toDate(event.start_date + ' ' + event.timezone), 'hh:mmaaa'))
		eventTime.push(
			format(
				toDate(event.end_date + ' ' + event.timezone),
				isEqual(start_date.setHours(0, 0, 0, 0), end_date.setHours(0, 0, 0, 0))
					? 'hh:mmaaa'
					: 'MM/dd/yyyy hh:mmaaa'
			)
		)

		if (eventMonth === '' || eventMonth != format(start_date, 'MMMM')) {
			monthHeading =
				'<div class="cc-event-month-separator"><span>' + format(start_date, 'MMMM yyyy') + '</span></div>'
			eventMonth = format(start_date, 'MMMM')
		}

		var eventWebsite = event.website || event.url

		//NWOC
		const eventNWOC = event.cc_is_nwoc_event
		const eventNWOCAccessible = event.cc_is_nwoc_event_accessible
		let accessibleSymbol = ``

		if (eventNWOCAccessible) {
			accessibleSymbol = `
			<div class="nwoc-accessible-symbol-container">
			<?xml version="1.0" encoding="UTF-8"?><svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><defs><style>.b{fill:#979797;}</style></defs><path class="b" d="M256,48c114.95,0,208,93.03,208,208s-93.03,208-208,208S48,370.97,48,256,141.03,48,256,48m0-40C119.03,8,8,119.03,8,256s111.03,248,248,248,248-111.03,248-248S392.97,8,256,8Zm0,56c-106.04,0-192,85.96-192,192s85.96,192,192,192,192-85.96,192-192-85.96-192-192-192Zm0,44c19.88,0,36,16.12,36,36s-16.12,36-36,36-36-16.12-36-36,16.12-36,36-36Zm117.74,98.02c-28.71,6.78-55.51,12.75-82.14,15.81,.85,101.02,12.31,123.05,25.04,155.62,3.62,9.26-.96,19.7-10.22,23.32-9.26,3.62-19.7-.96-23.32-10.22-8.7-22.31-17.09-40.64-22.26-78.55h-9.69c-5.17,37.85-13.53,56.21-22.26,78.55-3.62,9.26-14.05,13.84-23.32,10.22-9.26-3.62-13.83-14.06-10.22-23.32,12.71-32.54,24.18-54.54,25.04-155.62-26.63-3.06-53.43-9.03-82.14-15.81-8.6-2.03-13.93-10.65-11.9-19.25,2.03-8.6,10.65-13.93,19.25-11.9,96.69,22.83,124.28,22.78,220.77,0,8.6-2.03,17.22,3.29,19.25,11.9,2.03,8.6-3.3,17.22-11.9,19.25Z"/></svg>
			</div> <span>Accessible</span>
			`
		}
		//let eventNWOCSector = event.cc_nwoc_sectors

		return html`
			${monthHeading}
			<div itemprop="event" itemscope itemtype="https://schema.org/Event" class="cc-event-row">
				<div
					class="cc-event-date"
					itemprop="startDate"
					content="${toDate(event.start_date + ' ' + event.timezone).toISOString()}"
				>
					${format(start_date, 'eee')}
					<span>${format(start_date, 'd')}</span>
				</div>
				<div class="cc-event-data">
					<div class="cc-event-time-info">
						<span class="cc-event-time">${eventTime.join(' - ')}</span>
						<span class="cc-event-time-zone">${format(start_date, 'zzz')}</span>
					</div>
					<h2 class="cc-event-heading" itemprop="name">
						<a href="${eventWebsite}" itemprop="url" target="_blank">${event.title}</a>
					</h2>

					<div
						class="cc-event-organization"
						itemprop="organizer"
						itemscope
						itemtype="https://schema.org/Organization"
					>
						<span itemprop="name">${event.organization}</span>
					</div>

					${eventNWOC
						? `<div class="cc-event-is-nwoc"><span>NWOC Event </span>${accessibleSymbol}</div>`
						: ``}

					<div class="cc-event-description" itemprop="description">
						${event.description}
					</div>
					<div class="cc-event-link" itemprop="url">
						<a href="${eventWebsite}" target="_blank">See More and Register</a>
					</div>
				</div>
			</div>
		`
	}

	// function getCurrentScriptUrlBase() {
	// 	var script_url = getCurrentScriptURL()
	// 	var url = new URL(script_url)

	// 	return url.protocol + url.hostname
	// }

	// function getCurrentScriptURL() {
	// 	var script = document.currentScript || document.querySelector('script[src*="bundle.events-widget-v1.js"]')
	// 	return script.src
	// }

	loadData()
})(jQuery)
